(function ($){
    /******
     * Lazify [Custom Coded]
     */
    var isInViewport = function (elem) {
        var distance = elem.getBoundingClientRect();
        //console.log(distance);
        return (
            distance.top >= 0 &&
            distance.left >= 0 &&
            distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            distance.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    var doLazify = function(){
        lazyLoadImages.forEach(function(lazyImage){
            if (isInViewport(lazyImage) & !$(lazyImage).hasClass('lazify-loaded')) {
                var src = lazyImage.dataset.lazify;
                //console.log(lazyImage);
                lazyImage.src = src;
                $(lazyImage).show();
                lazyImage.classList.add('lazify-loaded');
            }
        });
    }
    const lazyLoadImages = document.querySelectorAll('img[data-lazify]')
    document.addEventListener("DOMContentLoaded", function() {
        doLazify();
    });

    window.addEventListener("scroll",function(){
        doLazify();
    });
    document.addEventListener("doLazify",function(e){
        doLazify();
    });
})(jQuery);